<template>
  <div>
    <div v-if="type == 'topic'" class="container" style="text-align: left; margin: 15px 0">
      <div v-if="!typeSelected" class="" style="text-align: center; margin: 10px 0">
        <div>
          <h4 style="text-align: center; margin: 25px" v-html="$t(`${type}_name_text`)"></h4>
          <div style="text-align: center; margin: 10px 0">
            <button v-if="!loadedList" class="btn btn-primary" :class="{ disabled: !loadedList }">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Подготовка...</span>
              </div>
              <span> Категория AB</span>
            </button>

            <button v-else class="btn btn-primary" :class="{ disabled: !loadedList }" @click.stop="setType(1)">
              <span v-if="ready"> Категория AB</span>
              <span v-else>Подготовка... </span>
            </button>
          </div>
          <div style="text-align: center; margin: 10px 0">
            <button v-if="lang !== 'kg' && false" class="btn btn-primary" :class="{ disabled: prepare }" @click.stop="setType(3)">
              <span v-if="ready"> Категория CD </span>
              <span v-if="prepare && !loadedList">Подготовка... </span>
            </button>
          </div>

          <div style="margin-top: 10px">{{ $t("Включить дополнительные вопросы") }}</div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :value="2" id="typeJOLDO" v-model="typeP" />
            <label class="form-check-label" for="typeJOLDO"> {{ $t(`Вопросы JOLDO`) }} </label>
          </div>
        </div>
      </div>

      <div v-else class="row justify-content-center">
        <div v-for="el in groups" :key="el.id" class="d-flex1 col-sm-6 col-lg-6 col-xl-6">
          <div class="" style="text-align: center; margin: 2px auto">
            <button :disabled="!ready" class="btn btn-secondary btn-block" style="min-height: 62px; margin: 2px" @click="onStartGroup(el.id)">
              <span v-if="ready">
                {{ getGroupName(el) }} <small>({{ getCount(el.id) }})</small>
              </span>
              <span v-else>Подготовка... {{ loadProgress }}%</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="type == 'subGroups'" class="container" style="text-align: left; margin: 15px 0">
      <div class="row justify-content-center">
        <div class="col-sm-6 col-lg-6 col-xl-6">
          <button
            class="btn btn-secondary btn-block"
            style="min-height: 62px; margin: 2px"
            @click="
              group_id = null;
              type = 'topic';
            "
          >
            ...Назад
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-for="(el, i) in subGroups.filter(g => g.group_id == group_id)" :key="i" class="d-flex1 col-sm-6 col-lg-6 col-xl-6">
          <div class="" style="text-align: center; margin: 2px auto">
            <button class="btn btn-secondary btn-block" style="min-height: 62px; width1: 280px; margin: 2px" @click="onStartGroup(group_id, el.items)">
              {{ el.name }} ({{ el.items.length }})
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    prepare: { type: Boolean, default: false },
    loadedList: Boolean,
    loadedB: Boolean,
    g: [Array, Object],
    q: Array,
    lang: String,
  },
  data() {
    return {
      type: "topic",
      typeSelected: false,
      typeP: [],
      ready: true,
      q_list: [],
      groups: [],
      subGroups: [],
      group_id: null,
    };
  },
  computed: {
    listGroups() {
      let res = this.groups;

      return res;
    },
  },
  watch: {
    prepare(v) {
      this.ready = !v;
    },
    typeP(v) {
      this.q_list = this.q.filter(q => {
        return this.typeP.includes(q.type);
      });
    },
  },
  methods: {
    getGroupName(el) {
      let res = el.name;
      if (this.lang != "ru") res = el?.lang_data?.name || res;
      return res;
    },
    getCount(id) {
      return this.q_list.filter(q => q.gr == id).length;
    },
    setType(n) {
      if (!this.ready) return;
      this.ready = false;
      this.typeP.push(n);
      this.q_list = this.q.filter(q => {
        return this.typeP.includes(q.type);
      });
      let sg = [];
      const makeSubMenu = (n, q) => {
        let name = n || "Без группировки";
        //let idx = sg.findIndex(g => g.name == name && g.group_id == q.gr);
        let idx = sg.findIndex(g => g.name == name && g.group_id == q.gr);
        if (idx == -1) sg.push({ group_id: q.gr, name, items: [q.id], count: 1 });
        else {
          sg[idx].items.push(q.id);
          sg[idx].count++;
        }
      };
      this.q_list.forEach(q => {
        makeSubMenu("Все", q);
        makeSubMenu(q?.group_name, q); //делим по подгруппе, если есть
      });
      let groups = [];
      sg.forEach(g => {
        if (!groups.includes(g.group_id)) groups.push(g.group_id);
      });
      this.groups = this.g.filter(g => groups.includes(g.id));

      this.subGroups = sg;
      this.typeSelected = true;
      this.ready = true;
    },
    async onStartGroup(id, items = null) {
      if (!id) return;
      if (!this.ready) return;
      this.group_id = id;
      this.ready = !true;

      if (!items) {
        if (this.subGroups.filter(g => g.group_id == id).length > 2) {
          this.type = "subGroups";
          this.ready = !false;
          return;
        }
        items = this.subGroups.find(g => g.group_id == id)?.items;
      }
      if (items) {
        if (items == [-1]) {
          items = [];
          this.q_list.forEach(q => {
            if ((items[0] == -1 || items.includes(q.id)) && this.typeP.includes(q.type) && q.gr == this.group_id) {
              items.push(q);
            }
          });
        }

        this.ready = !false;
        //this.groupBilet = { id, group };
        this.$emit("setBilet", {
          id,
          items,
          total: this.q_list.filter(q => {
            return q.gr == this.group_id && q.type !== 2;
          }).length,
        });
      } else {
      }
    },
  },
};
</script>
